.params {
}

.title {
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9e9;
  font-weight: 600;
  font-size: 16px;
}

.content {
  padding: 24px;
}
