.postpone {
  color: #575F59;
  cursor: pointer;
  white-space: nowrap;
}

.wrapper {
  span {
    white-space: nowrap;
  }
}
