@import "src/theme";

.link {
  width: 38px;
  border-radius: 50px;
  height: 38px;
  min-height: auto;
  min-width: auto;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5C6A61;
  color: white;
  transition: all 0.2s linear;

  &:hover {
	background-color: $accent;
	color: white;
  }
}
