.categories {
  padding: 24px;
  border-bottom: 1px solid #e9e9e9;
  :global(.ant-collapse-item) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.ant-collapse-header) {
    padding: 0 !important;
    //display: inline-block!important;
    //border-bottom: 1px dashed grey!important;
    font-size: 15px !important;
    color: #2f2f2f !important;
    transition: all 0.2s linear !important;
  }

  :global(.ant-collapse-item:last-child .ant-collapse-header) {
    border-radius: 0 !important;
  }
}
