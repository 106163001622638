.sectionsWrapper {
  :global {
    .ant-collapse {
      border-radius: 0;
    }

    .ant-collapse-header {
      padding: 25px 20px !important;
      font-weight: 600;
      border-bottom: 1px solid white!important;
    }

    .ant-collapse-item {
      background-color: #ffffff;
      margin-bottom: 10px;

      &-active {
        .ant-collapse-header {
          border-radius: 0 !important;
          border-bottom: 1px solid #E9E9E9!important;
        }
      }
    }
  }
}

.button {
  margin: 20px 20px 0 20px;

  button {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .sectionsWrapper {
    :global {
      .ant-collapse {
        border-radius: 5px;
      }
    }
  }
  .button {
    margin: 30px 0 0 0;

    button {
      width: 250px;
    }
  }
}
