.button {
  padding: 0;
  height: auto;
  width: auto;
}

.input {
  background-color: #f7f7f7;
  border-radius: 50px;
  input {
    background-color: #f7f7f7;
  }
}
