.btn {
  background-color: #f4f4f4;
  border: 1px solid #cfcfcf;
  color: #7A7A7A;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .ghost {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }
}
