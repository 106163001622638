.header {
  display: none;
}

@media screen and (max-width: 991px) {
  .header {
    display: initial;
    font-size: 15px;
    color: #7a7a7a;
    margin-right: 5px;
  }
}
