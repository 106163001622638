.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.linkWrapper {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .wrapper {
	flex-direction: row;
	align-items: center;
  }
  .linkWrapper {
	margin-left: auto;
	margin-top: 0;
	min-width: 240px;
  }
}

@media screen and (min-width: 1400px) {
  .linkWrapper {
	flex-wrap: nowrap;

	a {
	  width: 240px;
	}
  }
}

