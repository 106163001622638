.status {
  display: flex;
  align-items: center;
}

.text {
  white-space: nowrap;
}

.date {
  white-space: nowrap;
}
