.cardWrapper {
  :global(.ant-card-body) {
	padding: 0;
  }
}

.managerInfo {
  display: flex;
  font-size: 15px;
  line-height: 19px;
  padding: 24px 24px 0 24px;
}

.buttonWrapper {
  padding: 24px;
}

.imageBox {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
  border-radius: 50%;
  overflow: hidden;
  font-size: 24px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.position {
  display: block;
  margin: 11px 0 13px 0;
  font-weight: 700;
  color: #7A7A7A;
  font-size: 14px;
}

@media screen and (min-width: 1920px) {
  .box {
	&:nth-child(odd) {
      border-right: 1px solid #E9E9E9;
    }
  }

  .imageBox {
	width: 100px;
	height: 100px;
  }
}
