.box {
  width: 100%;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
