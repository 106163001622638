.button {
  display: none;
  order: 2;

  &:disabled {
	cursor: not-allowed;
	background-color: darken(#fff, 5);
  }
}

@media screen and (min-width: 768px) {
  .button {
	background-color: white;
	border-radius: 50px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	margin-left: 10px;
	cursor: pointer;

	&:hover {
	  .icon {
		fill: black;
	  }
	}
  }
  .icon {
	fill: #686a69;
  }
}
