.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  :global(.ant-select-selector) {
    border-radius: 0 !important;
  }
}

.orderText {
  display: none;
}
.dropdown {
  width: 100%;
}

.marginReset {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .wrapper {
    :global(.ant-select-selector) {
      border-radius: 50px !important;
    }
  }
  .orderText {
    display: initial;
    margin-right: 20px;
    font-size: 18px;
    color: #7a7a7a;
  }

  .dropdown {
    width: 360px !important;
  }
}
