.text {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 23px;

  p {
	margin-bottom: 0;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  a {
	margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .buttons {
	flex-direction: row;
	a {
	  margin-bottom: 0;
	  margin-right: 20px;
	}
  }
}
