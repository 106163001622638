.title {
  display: block;
  font-weight: 600;
  color: #7a7a7a;
}

@media screen and (min-width: 768px) {
  .text {
	display: flex;
  }
  .content {
	flex: 1;
	margin-left: 50px;
  }
}

@media screen and (min-width: 1400px) {
  .content {
	margin-left: 110px;
  }
}
