.breadcrumbs {
  display: none;
}

@media screen and (min-width: 768px) {
  .breadcrumbs {
    display: flex;
    margin: 30px 0;
    list-style: none;
    padding: 0;
  }

  .item {
    margin-right: 30px;
    position: relative;

    &:after {
      content: '»';
      position: absolute;
      right: -20px;
    }

    a {
      color: #2e2e2e;
      font-size: 16px;
      line-height: 16px;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }

      a {
        pointer-events: none;
        cursor: not-allowed;
        color: #575f59;
      }
    }
  }
}
