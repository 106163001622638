.block {
  span {
    font-weight: normal;
  }
}

.block {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .block {
    text-align: right;
  }
  .summary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .block {
    margin-bottom: 0;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}
