@import '../../../theme';

.price {
  display: none;
}

.counter {
  display: none;
}

.icon {
  width: 20px;
  height: 20px;
  fill: #434343;
}

@media screen and (min-width: 768px) {
  .cart {
    margin: 0 10px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    padding: 3px;
  }
  .price {
    display: inline-block;
    margin: 0 20px 0 10px;
    color: black;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
  }
  .wrapper {
    width: 50px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $accentBg;
    border-radius: 50px 0 50px 50px;
    position: relative;
  }
  .icon {
    fill: $accent;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin: 0 20px 0 10px;
  }
  .counter {
    display: inline;
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
}
