
.select {
  :global(.ant-select-selector) {
	background-color: #f7f7f7 !important;
  }
}

.input {
  background-color: #f7f7f7;
  border-radius: 50px;
  width: 100%;
}

.text {
  display: none !important;
}

.buttons {
  padding: 20px 0;
}

@media screen and (max-width: 767px) {
  .wrapper {
	button {
	  padding: 0;
	}
  }
}

@media screen and (min-width: 768px) {
  .text {
	display: initial !important;
	margin-left: 10px;
	font-size: 16px;
  }
}

.form {
  padding: 20px;
}

