.footer {
  flex-shrink: 0;
  margin-top: 60px;
  padding: 30px;
  background-color: #0b1620;
  color: #738392;
  text-align: center;
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  .footer {
    background-color: transparent;
    text-align: right;
    margin-top: 0;
  }
}
