.buttons {
  margin-top: 20px;
  button {
	display: block;
	width: 100%;
	&:first-child {
	  margin-bottom: 15px;
	}
  }
}

@media screen and (min-width: 992px) {
  .buttons {
	button {
	  display: initial;
	  width: auto;
	  min-width: 161px;
	  &:first-child {
		margin-bottom: 0;
		margin-right: 20px;
	  }
	}
  }
}

@media screen and (min-width: 1400px) {
  .buttons {
	margin-top: 30px;
  }
}
