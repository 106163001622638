@import "node_modules/antd/dist/antd.less";
@import "src/ant-custom-styles";

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #101712;
  font-size: 15px;
  background-color: #eaeaea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}



@media screen and (max-width: 767px) {
  .mobile-border-none {
    border-radius: 0 !important;
  }
}

@primary-color: #18A341;@link-color: #18A341;@layout-header-background: #151816;@btn-border-radius-base: 50px;@border-radius-base: 5px;@border-color-base: white;@height-lg: 54px;@btn-height-base: 50px;@btn-padding-horizontal-base: 25px;@btn-height-lg: 54px;@btn-height-sm: 28px;@table-padding-vertical: 20px;@table-padding-horizontal: 20px;@table-header-bg: white;@input-padding-horizontal-lg: 28px;@select-dropdown-vertical-padding: 10px;@select-single-item-height-lg: 54px;@tag-default-color: #101712;@tag-font-size: 15px;@drawer-body-padding: 0;@page-header-padding: 0;@page-header-padding-vertical: 0;@screen-xl: 1400px;@screen-xxl: 1920px;