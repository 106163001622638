.block {
  display: flex;
  align-items: flex-start;
}

.content {
  margin-left: 12px;
}

.text {
  margin-bottom: 5px;
  font-size: 15px;
  white-space: normal;
}

.char {
  font-size: 13px;
  color: #575f59;
}
