@import 'src/theme';

.documentsPage {
  margin-top: 10px;

  :global(.ant-tabs-nav-wrap) {
    padding-left: 20px;
  }

  :global(.ant-tabs-nav:before) {
    border-bottom: none;
  }

  :global(.ant-tabs-ink-bar) {
    display: none;
  }

  :global(.ant-tabs-tab-btn) {
    padding: 0 26px;
    font-size: 14px;
  }

  :global(.ant-tabs-tab + .ant-tabs-tab) {
    margin: 0 0 0 16px;
  }

  :global(.ant-tabs-tab) {
    border: 1px solid transparent;
    border-radius: 50px;
    color: #575f59;
  }

  :global(.ant-tabs-tab-active) {
    background-color: white;
    border-color: $accent;
    color: $accent;
  }
}

@media screen and (min-width: 768px) {
  .catalogPage {
    :global(.ant-tabs-nav-wrap) {
      padding-left: 0;
    }
  }
}
