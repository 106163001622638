.title {
  font-weight: 600;
  font-size: 28px;
}

.password {
  border-radius: 50px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
