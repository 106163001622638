.block {
  margin-bottom: 20px;
}

.form {
  max-width: 450px;
}

.range {
  width: 100%;
  background-color: #f7f7f7;
}
.select {
  width: 100%;

  :global {
    .ant-select-selector {
      background-color: #f7f7f7 !important;
    }
  }
}
