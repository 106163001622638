.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .amount {
    justify-content: flex-start;
    strong {
      margin-right: 5px;
    }
  }
}
