.wrapper {
  padding: 100px 20px;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 25px auto;
}
