
.tag {
  min-width: 95px;
  min-height: 30px;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin: 0 10px 10px 0;
  transition: background-color 0.2s linear;
}

.disabled {
  background-color: darken(white, 7);
  cursor: not-allowed;

  button {
	cursor: not-allowed;
  }
}

.icon {
  color: #999999;
  font-size: 13px;
}

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: auto;
  margin-left: 5px;
}
