.tags {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 10px;
  padding: 0 20px;

  :global(.ant-tag) {
    margin-bottom: 8px;
  }
}


@media screen and (min-width: 768px) {
  .tags {
    padding: 0;
    border-bottom: none;
  }
}
