.button {
  width: 165px;
  height: 40px;
  padding: 0;
  border: 1px solid #e9e9e9;
  background-color: #f4f4f4;
  color: #575f59;

  svg {
	fill: #7a7a7a;
  }
}

.text {
  font-size: 14px;
  color: #575f59;
}

@media screen and (min-width: 992px) {
  .button {
	background-color: transparent;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	box-shadow: none;
  }
  .text {
	display: none !important;
  }
}
