.title {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}

.container {
  padding: 0 20px;
}

@media screen and (min-width: 768px) {
  .text {
    width: 700px;
  }

  .container {
    padding: 0;
  }
}
