@import 'src/theme';

.link {
  font-size: 14px;
  padding: 11px 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $accent;
  border: 1px solid $accent;
  border-radius: 50px;
  color: white;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: darken($accent, 10);
    border-color: darken($accent, 10);
  }
}

.ghost {
  background-color: transparent;
  color: $accent;

  &:hover,
  &:active,
  &:focus {
    background-color: $accent;
    color: white;
    border-color: $accent;
  }
}
