.wrapper {
}

.denied {
  padding: 50px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 95px;
  overflow-x: hidden;
}

.main {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: flex;
  }

  .content {
    width: 100%;
    padding-top: 20px;
  }
}
