.card {
  background-color: white;
  margin-top: 50px;
}

.cardHeader {
  padding: 20px;
  border-bottom: 1px solid #e9e9e9;
}

.title {
  padding: 20px;
  background-color: #f4f4f4;
  font-size: 15px;
  font-weight: 600;
}

.subTitle {
	display: block;
}

.info {
  padding: 20px;
  font-size: 15px;

  p {
	margin: 0;

	& > strong {
	  display: block;
	  margin-bottom: 5px;
	}

	& > span {
	  color: #575f59;
	}
  }
}

@media screen and (min-width: 768px) {
  .cardHeader,
  .title,
  .info {
	padding: 20px 30px;
  }
  .card {
	border-radius: 5px;
  }
}
