.form {
  padding: 20px;
}

.range,
.input {
  width: 100%;
  background-color: #f7f7f7;

}
