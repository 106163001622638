@import '../../../theme';

.navigation {
  background-color: $secondary;
  position: fixed;
  width: 100%;
  left: 0;
  top: 65px;
  border-top: 1px solid #d7e2ea;
  right: 0;
  bottom: 0;
  transform: translate(-100%, 0);
  transition: transform 0.2s linear;
  z-index: 1001;
}

.navShow {
  transform: translate(0, 0);
}

.list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.icon {
  margin-right: 15px;
  fill: #b9c3cb;
}

.item {
  padding: 0;
}

.divider {
  margin-top: 45px;
}

.link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 16px;
  line-height: 16px;
  color: #1e2125;
  transition: all 0.2s linear;

  &:hover,
  &:active,
  &:focus {
    color: #1e2125;
    background-color: #e2e7eb;
  }
}

@media screen and (min-width: 768px) {
  .navigation {
    background-color: #161816;
    position: static;
    transform: translate(0, 0);
    width: auto;
    padding-top: 30px;
  }
  .icon {
    margin-right: 0;
    fill: white;
  }

  .link {
    color: white;

    &:hover,
    &:active,
    &:focus {
      color: inherit;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  .active {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .text {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .navigation {
    width: 320px;
    flex-shrink: 0;
    border-top: none;
  }

  .link {
    border-radius: 50px;
    margin: 5px 15px;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      color: white;
    }
  }

  .divider {
    margin-top: 90px;
  }

  .text {
    display: initial;
    margin-left: 15px;
    font-size: 16px;
  }
}
