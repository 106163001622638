.buttonWrapper {
  margin-left: auto;
}

.text {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .buttonWrapper {
	margin-right: 20px;
	button {
	  height: auto;
	  padding: 0;
	}
  }
}

@media screen and (min-width: 768px) {
  .text {
	display: initial !important;
	margin-left: 10px;
	font-size: 16px;
  }
}
