.counter {
  width: 120px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f8f6;
  border-radius: 50px;
  padding: 10px 15px;
}

.decrement,
.increment {
  color: #79867d;
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.input {
  background-color: transparent;
  border: none;
  flex: 1;
  text-align: center;
  max-width: 50px;
  font-weight: 600;
}

.icon {
  width: 50px;
  font-size: 22px;
  margin: auto;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.disabled {
  cursor: not-allowed;
  background-color: darken(#f5f8f6, 5);

  button,
  input {
	pointer-events: none;
	cursor: not-allowed;
  }
}

.reset {
  margin: 0;
}
