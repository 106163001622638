.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
  border-top:1px solid #D3D3D3;
}

@media screen and (min-width: 768px)  {
  .container {
    padding: 0;
    border-top: none;
    margin-bottom: 20px;
  }
}
