.input {
  background-color: #f7f7f7;
  resize: none;
}


@media screen and (min-width: 768px) {
  .input {
	border-radius: 5px !important;
	min-height: 140px;
  }
}
