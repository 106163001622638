.info {
  display: none;
}



@media screen and (min-width: 768px) {
  .info {
    display: block;
    font-size: 16px;
    line-height: 23px;
    margin: 25px 0 50px 0;
    width: 350px;
    color: black;
  }

}
