// SELECT

.ant-select-selector {
  border-radius: 50px !important;
}

.ant-select-arrow {
  top: 50% !important;
  right: 28px !important;
  font-size: 15px !important;
}

.ant-pagination {
  .ant-select-arrow {
    display: none;
  }
}

// CHECKBOX
.ant-checkbox-inner,
.ant-tree-checkbox-inner {
  border: 1px solid #d9d9d9 !important;
}

// TAG
.ant-tag {
  padding: 5px 10px !important;
}

.ant-tag-close-icon {
  margin-left: 10px !important;
}

.ant-select-clear {
  right: 28px !important;
  font-size: 15px !important;
}

.ant-select-selection-search {
  left: 28px !important;
}

// DRAWER
.ant-drawer-content-wrapper {
  width: 100% !important;
}

.ant-drawer-title {
  font-weight: 600 !important;
}

@media screen and (min-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 410px !important;
  }
}


// DARK

.ant-input-dark-bg {
  .ant-select-selector, input, textarea {
    background-color: #F7F7F7 !important;
  }
}


@media screen and (max-width: 767px) {
  .select-border-none {
    .ant-select-selector {
      border-radius: 0 !important;
    }
  }
}

// INPUT
.ant-input {
  border-radius: 50px !important;
}

// TABLE
.ant-table-title {
  font-weight: 600;
}

.ant-table-thead > tr > th {
  border-top: 1px solid #f0f0f0;
  font-weight: 600;
  color: #575F59;
  white-space: nowrap;
}

.ant-pagination-item-active {
  background-color: #DEF2E4 !important;
  border-color: #DEF2E4 !important;
}

.ant-table {

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }

  @media screen and (max-width: 991px) {
    display: block;
    &-title {
      border-bottom: 1px solid #eeeeee;
    }

    &-thead {
      display: none;
    }

    &-thead > tr,
    &-tbody > tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0.5rem 1rem;

        &:last-child {
          border-bottom: 20px solid #eee;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  h1 {
    margin: 30px 0 40px 0;
  }
}

// PAGEHEADER
.ant-page-header-heading {
  align-items: flex-start;
  padding: 20px 20px 0 20px;
  margin-bottom: 30px;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: nowrap;
}

.ant-page-header-heading-title {
  font-size: 28px;
  line-height: 31px;
  font-weight: 600;
  white-space: initial !important;
}

@media screen and (min-width: 768px) {
  .ant-page-header-heading {
    padding: 0;
  }

  .ant-page-header-heading-title {
    font-size: 38px;
    line-height: 38px;
  }
}


// DROPDOWN MENU
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 10px 20px;
}


// PICKER
.ant-picker {
  border-radius: 50px;
}


// DESCRIPTIONS

.ant-descriptions-item-label {
  font-weight: 600;
  white-space: nowrap;
}
