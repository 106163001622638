.banners {
  display: block;
  margin-top: auto;
  padding-top: 20px;
}
.mobile,
.tablet,
.desktop {
  width: 100%;
}
.tablet,
.desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }

  .tablet {
    display: initial;
  }
}

@media screen and (min-width: 1400px) {
  .tablet {
    display: none;
  }

  .desktop {
    display: initial;
  }
}
