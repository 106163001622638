.order {
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.company {
  display: block;
  font-size: 13px;
  line-height: 13px;
  color: #575f59;
  margin-bottom: 5px;
}
