.forgot {
  text-align: right;
  display: inline-block;
  margin-left: auto;
  background: none;
  border: none;
  color: #3da519;
  cursor: pointer;
}

.input {
  background-color: #f7f7f7;
}

.result {
  padding: 0;
}
