.wrapper {
  width: 100%;
}

.form {
  position: relative;
}

.input {
  padding-right: 60px !important;
}

.btn {
  position: absolute;
  right: 20px;
  border: none;
  background-color: transparent;
  font-size: 21px;
  cursor: pointer;
  z-index: 1;
  top: 10px;

  svg {
    fill: #686a69;
  }
}

.disabled {
  .input {
    background-color: darken(white, 7);
    border-color: darken(white, 7);
  }

  .btn {
    cursor: not-allowed;
  }
}
