@media screen and (max-width: 767px) {
  .buttonsWrapper {
	padding: 0 20px;
	:global(.ant-divider) {
	  display: none !important;
	}

	button {
	  width: 100%;
	  margin-bottom: 10px;
	}
  }

}
