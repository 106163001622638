.icon {
  width: 20px;
  height: 20px;
  fill: #434343;
}

.chevron {
  display: none;
}

.wrapper {
  margin-left: 20px;
  cursor: pointer;
}

.text {
  display: none;
}

@media screen and (min-width: 768px) {
  .wrapper {
    margin: 0 10px;
    display: flex;
    align-items: center;
  }

  .box {
    background-color: white;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    padding-left: 1px;
  }

  .chevron {
    display: inherit;
    fill: #9e9e9e;
    width: 24px;
    height: 12px;
  }

  .text {
    margin: 0 5px 0 15px;
    font-size: 16px;
    line-height: 16px;
    display: inherit;
    font-weight: 600;
  }
}
