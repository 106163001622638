.org {
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  padding: 3px 20px;
  background-color: #eef2f5;
  border-top: 1px solid #d8e2ea;
  font-size: 13px;
  text-align: center;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .org {
    position: static;
    background-color: transparent;
    font-size: 16px;
    border-top: 0;
    order: 1;
    padding: 25px 0 0 0;
    text-align: left;
    flex: 1;
    min-width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .org {
    order: initial;
    flex: auto;
    min-width: auto;
    margin-left: 90px;
    max-width: 650px;
    padding: 0;
    font-size: 18px;
    white-space: pre-wrap;
  }
}
