@import '../../../theme';

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 65px;
  background-color: $secondary;
  display: flex;
  align-items: center;
  z-index: 1000;
  padding: 0 20px;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

// HAMBURGER
.hamburgerWrapper {
  margin-right: 20px;
}

.hamburger {
  transform: scale(0.7);
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.isActive:hover {
  opacity: 0.7;
}

.hamburger.isActive .hamburgerInner,
.hamburger.isActive .hamburgerInner::before,
.hamburger.isActive .hamburgerInner::after {
  background-color: #9da2a8;
}

.hamburgerBox {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  background-color: #000;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  width: 33px;
  height: 4px;
  border-radius: 4px;
}

.hamburgerInner::before,
.hamburgerInner::after {
  content: '';
  display: block;
}

.hamburgerInner::before {
  top: -10px;
}

.hamburgerInner::after {
  bottom: -10px;
}

/*
   * Slider
   */
.hamburgerSlider .hamburgerInner {
  top: 2px;
}

.hamburgerSlider .hamburgerInner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburgerSlider .hamburgerInner::after {
  top: 20px;
}

.hamburgerSlider.isActive .hamburgerInner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburgerSlider.isActive .hamburgerInner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburgerSlider.isActive .hamburgerInner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

@media screen and (min-width: 768px) {
  .header {
    position: static;
    background-color: transparent;
    flex-wrap: wrap;
    height: auto;
  }

  .hamburgerWrapper {
    display: none;
  }

  .actions {
    display: flex;
    align-items: center;
  }

  .logo img {
    width: 185px;
  }
}

@media screen and (min-width: 992px) {
  .header {
    padding: 0 30px;
  }
}
