.container {
  width: 100%;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    padding: 0 30px;
  }
}
