.wrapper {
  display: flex;
  align-items: center;
}

.notInStock {
  svg {
    fill: #ea4a17;
  }
}

.aFew {
  svg {
    fill: #ffa030;
  }
}

.inStock {
  svg {
    fill: #46af65;
  }
}

.text {
  margin-left: 10px;
  white-space: nowrap;
}
